import React, { Fragment } from 'react';

const BuyMeACoffe = () => {
  return (<Fragment>
    <link href="https://fonts.googleapis.com/css?family=Cookie" rel="stylesheet" />
    <a className="bmc-button" target="_blank" rel="noopener noreferrer" href="https://www.buymeacoffee.com/alexdhaenens">
      <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a cup of tea" />
      <span >Buy me a cup of tea</span>
    </a>
  </Fragment>);
};

export default BuyMeACoffe;
