import React from 'react';
import { Container } from 'react-bootstrap';

const ContentBlock = ({ title, children }) => {
  return (<Container className="content-block">
    <h2 className="content-block__title">
      {title}
    </h2>
    {children}
  </Container>)
};

export default ContentBlock;
