import React, { Fragment } from 'react';
import Page from '../components/Page';
import ContentBlock from '../components/ContentBlock';
import ImageContentBlockRow from '../components/ImageContentBlockRow';
import question from '../../svg/question.svg'

const apm_releaseRepo = "https://github.com/AlexDhaenens/apm_release/issues";

const Support = () => {
  return (<Page>
    <ContentBlock title="Support">
      <ImageContentBlockRow
        title="Bug support or feature request?"
        text={<Fragment>You can create issues <a href={apm_releaseRepo}>here</a>. </Fragment>}
        img={question}
      >

      </ImageContentBlockRow>
    </ContentBlock>
  </Page>)
}

export default Support;
