import React from 'react';
import icon from '../../icon.svg';
import BuyMeACoffe from './BuyMeACoffe';

const Footer = () => {
  return (<footer>
    <div className="footer__left">
      <img
        src={icon}
        width="50"
        height="50"
        className="d-inline-block align-top"
        alt="apm"
      />
      <p className="footer__block-content">&copy; 2020 Alex Dhaenens</p>
    </div>
    <div className="footer__right">
      <div className="footer__block">
        <p className="footer__block-title">Support the project</p>
        <BuyMeACoffe />
      </div>
      <div className="footer__block">
        <p className="footer__block-title">Other Credits</p>
        <p className="footer__block-content">Icons by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
      </div>
    </div>
  </footer>)
};

export default Footer;
