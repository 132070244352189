import React, { Fragment } from 'react';
import Page from '../components/Page';
import ContentBlock from '../components/ContentBlock';
import ChildrenContentBlockRow from '../components/ChildrenContentBlockRow';
import windows from '../../svg/windows.svg'

const apm_releaseRepo = "https://github.com/AlexDhaenens/apm_release/";
const latestVersion = "0.9.2"

const generateReleaseLink = () => {
  return `${apm_releaseRepo}raw/master/apm ${latestVersion}.msi`;
}
const Download = () => {
  const url = generateReleaseLink();
  return (<Page>
    <ContentBlock title="Download">
      <ChildrenContentBlockRow
        title="Latest version"
        text={<Fragment>You can download other versions <a href={apm_releaseRepo}>here</a>. </Fragment>}
      >
        <a className="btn" href={url} target="_blank" rel="noopener noreferrer"><span>Download</span><img src={windows} alt="windows" /></a>
      </ChildrenContentBlockRow>
    </ContentBlock>
  </Page>)
}

export default Download;
