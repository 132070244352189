import React from 'react';

const Hero = ({ title, subtitle, img }) => {
  return (<div className="hero">
    <div className="hero__content">
      <div className="hero__title-container">
        <h1 className="hero__title">{title}</h1>
        <h2 className="hero__subtitle">{subtitle}</h2>
      </div>
    </div>
    <div className="hero__footer">
      {img && <img src={img} className="hero__image" alt="heroImage" />}
    </div>
  </div>)
};

export default Hero;
