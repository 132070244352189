import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from './js/pages/Home';
import Download from './js/pages/Download';
import Support from './js/pages/Support';
import FAQ from './js/pages/FAQ';

function App() {
  return (
    <Router>
      <div className="app-container">
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/download">
            <Download />
          </Route>
          <Route path="/support">
            <Support />
          </Route>

          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
