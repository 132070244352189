import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import icon from '../../icon.svg';

/*
<Nav.Item>
                <LinkContainer to="/download">
                  <Nav.Link>Download</Nav.Link>
                </LinkContainer>
              </Nav.Item>

              <Nav.Item>
                <LinkContainer to="/documentation" >
                  <Nav.Link>Documentation</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              */

const Header = ({ darkHeader }) => {
  return (
    <header className={`app-header ${darkHeader ? "app-header--dark" : ""}`}>
      <Container>
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand href="/"><img
            src={icon}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="apm"
          /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="justify-content-end">
              <Nav.Item>
                <LinkContainer to="/download">
                  <Nav.Link>Download</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item>
                <LinkContainer to="/faq">
                  <Nav.Link>FAQ</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item>
                <LinkContainer to="/support">
                  <Nav.Link>Support</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item><Nav.Link href="https://www.alexdhaenens.com">Author</Nav.Link></Nav.Item>
            </Nav>
          </Navbar.Collapse>

        </Navbar>
      </Container>
    </header>
  )
}

Header.propTypes = {
  darkHeader: PropTypes.bool
};

Header.defaultProps = {
  darkHeader: false
};

export default Header;
