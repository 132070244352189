import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Page = ({ title, darkHeader, children }) => {
  return (<div>
    <Header darkHeader={darkHeader} />
    <div className="page">
      {title && <h3 className="page-title">{title}</h3>}
      {children}
    </div>
    <Footer />
  </div>)
};

export default Page;
