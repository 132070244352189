import React from 'react';

const ChildrenPane = ({ children }) => {
  return (<div className="content-block__row-children">
    {children}
  </div>)
};
const ChildrenContentBlockRow = ({ title, text, children, reverse }) => {
  return (<div className={`content-block__row ${reverse ? "content-block__row--reverse" : ""}`}>
    {reverse && <ChildrenPane children={children} />}
    <div className="content-block__row-content">
      <h3 className="content-block__row-title">
        {title}
      </h3>
      <p>{text}</p>
    </div>
    {!reverse && <ChildrenPane children={children} />}
  </div>)
};

export default ChildrenContentBlockRow;
