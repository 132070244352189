import React from 'react';
import Page from '../components/Page';
import ContentBlock from '../components/ContentBlock';
import ContentBlockRow from '../components/ContentBlockRow';


const FAQ = () => {
  return (<Page>
    <ContentBlock title="FAQ">
      <ContentBlockRow
        title="Why does apm require administrator privileges?"
        text="Windows requires admin rights to start & stop app pools & services. Therefor apm must run in admin mode."
      />
      <ContentBlockRow
        title="Why does an old version of visual studio open?"
        text="You have an older version installed, see this to fix that: https://stackoverflow.com/questions/6880431/"
      />
      <ContentBlockRow
        title="Can apm be used for non-sitecore projects?"
        text="Yes, altough it has been built with sitecore in mind (as sitecore solutions tipically have many services & app pools) non of the features are exclusive for sitecore projects and everthing can be used for other kinds of web application projects."
      />
      <ContentBlockRow
        title="Is apm free?"
        text="Yes, the application is free and does not require any subscribtion."
      />
      <ContentBlockRow
        title="What does apm stand for?"
        text="Awesome project manager"
      />
    </ContentBlock>
  </Page>)
}

export default FAQ;
