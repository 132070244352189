import React from 'react';

const Image = ({ img, title, hideImgOnMobile }) => {
  return (<div className={`content-block__row-image ${hideImgOnMobile ? "content-block__row-image--hidemobile" : ""
    }`}>
    <img src={img} alt={title} />
  </div>)
};
const ImageContentBlockRow = ({ title, text, img, reverse, hideImgOnMobile = false }) => {
  return (<div className={`content-block__row ${reverse ? "content-block__row--reverse" : ""} `}>
    {reverse && <Image img={img} title={title} hideImgOnMobile={hideImgOnMobile} />}
    <div className="content-block__row-content">
      <h3 className="content-block__row-title">
        {title}
      </h3>
      <p>{text}</p>
    </div>
    {!reverse && <Image img={img} title={title} hideImgOnMobile={hideImgOnMobile} />}
  </div>)
};

export default ImageContentBlockRow;
