import React from 'react';

const ContentBlockRow = ({ title, text }) => {
  return (<div className="content-block__row">

    <div className="content-block__row-content content-block__row-content--fullwidth">
      <h3 className="content-block__row-title">
        {title}
      </h3>
      <p>{text}</p>
    </div>
  </div>)
};

export default ContentBlockRow;
