import React from 'react';
import Page from '../components/Page';
import Hero from '../components/Hero';
import ContentBlock from '../components/ContentBlock';
import ImageContentBlockRow from '../components/ImageContentBlockRow';
import projects from '../../svg/project-management.svg';
import services from '../../svg/support.svg';
import search from '../../svg/search.svg';

const Home = () => {
  return (<Page darkHeader>
    <Hero title="Project management for devs" subtitle="One app to manage them all" img="/hero.png" />
    <ContentBlock title="Why use apm?">
      <ImageContentBlockRow
        title="Too many projects"
        text="You work on many different projects and are often switching between them and it is cumbersome and time consuming"
        img={projects}
      />
      <ImageContentBlockRow
        title="Too many windows services & app pools"
        text="Those projects have too many services & app pools. In order to avoid your pc lagging you don't run them all at once"
        img={services}
        reverse
        hideImgOnMobile
      />
      <ImageContentBlockRow
        title="Too many sites, folders & files"
        text="You can't always remember the sites, folders or file locations used for your projects because there are too many of them"
        img={search}
      />
    </ContentBlock>
    <ContentBlock title="What can apm do?">
      <ImageContentBlockRow
        title="Start all services & app pools with one click"
        text="With the press of a button all services (including databases) and app pools required by the project can by started."
        img="/startstop.png"
        hideImgOnMobile
      />
      <ImageContentBlockRow
        title="Start your dev tools fast"
        text="You can start your visual studio solution or visual code workspace directly by pressing one button"
        img="/tools.png"
        reverse
        hideImgOnMobile
      />
      <ImageContentBlockRow
        title="Project information at a glance"
        text="With one single look, you have a view on the resource utilization, current git branch,... of your project"
        img="/status.png"

      />
      <ImageContentBlockRow
        title="Open folders & sites quickly"
        text="You can configure per project diferent sites & folders that can be opened by pressing a button"
        img="/folder_sites.png"
        reverse
        hideImgOnMobile
      />
    </ContentBlock>
  </Page>)
}

export default Home;
